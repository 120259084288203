import { isNud } from '@/utilities/typing'

/**
 * 店舗マスタ エンティティ
 */
class Studio {
    studio_id;
    studio_name;
    priority;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.studio_id = properties.studio_id;
        this.studio_name = properties.studio_name;
        this.priority = properties.priority;
    }
}

export default Studio;
