import Enum from '../enum'

/**
 * スケジュールカメラマン 固定フラグ
 */
class IsLocked extends Enum {
    static NO = 0; // 非固定
    static YES = 1; // 固定

    static values() {
        return {
            [this.NO]: '非固定',
            [this.YES]: '固定',
        }
    }
}

export default IsLocked;