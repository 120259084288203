import { isNud } from '@/utilities/typing'
import { dateYmd } from '@/utilities/date-format';
import Schedule from '@/models/entities/schedule';
import Employee from '@/models/entities/employee';

/**
 * スケジュールメモトラン エンティティ
 */
class ScheduleMemo {
    schedule_memo_id;
    schedule;
    employee;
    memo_date;
    content;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.schedule_memo_id = properties.schedule_memo_id;
        this.schedule = new Schedule(properties.schedule);
        this.employee = new Employee(properties.employee);
        this.memo_date = properties.memo_date;
        this.content = properties.content;
    }

    get memo_date_display() {
        return dateYmd(this.memo_date);
    }

    get content_display() {
        let content = this.content.replace(/\r\n/g, "<br />");
        content = content.replace(/(\n|\r)/g, "<br />");
        return content;
    }
}

export default ScheduleMemo;
