import Enum from '../enum'

/**
 * スケジュール 複数日かどうか
 */
class HasSeveralDays extends Enum {
    static NO = 0; // 複数日でない
    static YES = 1; // 複数日

    static values() {
        return {
            [this.NO]: '複数日でない',
            [this.YES]: '複数日',
        }
    }
}

export default HasSeveralDays;