import { isNud } from '@/utilities/typing'
import File from '@/models/entities/file';

/**
 * スケジュールファイルトラン エンティティ
 */
class ScheduleFile {
    schedule_file_id;
    file;
    file_url;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.school_id = properties.school_id;
        this.file = new File(properties.file);
        this.file_url = properties.file_url;
    }
}

export default ScheduleFile;
