import { isNud } from '@/utilities/typing'
import Employee from '@/models/entities/employee';
import AbsenceTime from '@/models/enums/schedule/absence-time';

/**
 * 休暇トラン エンティティ
 */
class Absence {
    absence_id;
    employee;
    absence_date;
    absence_type;
    absence_time;
    absence_memo;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.absence_id = parseInt(properties.absence_id, 10);
        this.employee = new Employee(properties.employee);
        this.absence_date = properties.absence_date;
        this.absence_type = parseInt(properties.absence_type, 10);
        this.absence_time = parseInt(properties.absence_time, 10);
        this.absence_memo = properties.absence_memo;
    }

    get absence_time_display() {
        return AbsenceTime.get(this.absence_time);
    }
}

export default Absence;
