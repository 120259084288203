import Enum from '../enum'

/**
 * スケジュール 未定かどうか
 */
class IsUnfixed extends Enum {
    static FIXED = 0; // 確定
    static UNFIXED = 1; // 未定

    static values() {
        return {
            [this.FIXED]: '確定',
            [this.UNFIXED]: '未定',
        }
    }
}

export default IsUnfixed;