import Enum from '../enum'

/**
 * アラート無視フラグ
 */
class IgnoreAlert extends Enum {
    static ALERT = 0; // アラート
    static IGNORE = 1; // 無視

    static values() {
        return {
            [this.ALERT]: 'アラート',
            [this.IGNORE]: '無視',
        }
    }
}

export default IgnoreAlert;