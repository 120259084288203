import { isNud } from '@/utilities/typing'

/**
 * スケジュール履歴 エンティティ
 */
class ScheduleHistory {


    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

    }
}

export default ScheduleHistory;
