import { isNud } from '@/utilities/typing'
import Schedule from '@/models/entities/schedule';
import Photographer from '@/models/entities/photographer';
import IsContacted from '@/models/enums/schedule/is-contacted';
import IsLocked from '@/models/enums/schedule/is-locked';
import IgnoreAlert from '@/models/enums/schedule/ignore-alert';

/**
 * スケジュールカメラマントラン エンティティ
 */
class SchedulePhotographer {
    schedule_photographer_id;
    schedule;
    photographer;
    is_contacted;
    is_locked;
    ignore_alert;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.school_id = properties.school_id;
        this.schedule = new Schedule(properties.schedule);
        this.photographer = new Photographer(properties.photographer);
        this.is_contacted = properties.is_contacted ?? IsContacted.NO;
        this.is_locked = properties.is_locked ?? IsLocked.NO;
        this.ignore_alert = properties.ignore_alert ?? IgnoreAlert.ALERT;
    }
}

export default SchedulePhotographer;
