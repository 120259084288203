import { isNud } from '@/utilities/typing'
import { dateYmd } from '@/utilities/date-format';
import Employee from '@/models/entities/employee';
import Partner from '@/models/entities/partner';

/**
 * 応援カメラマン依頼トラン エンティティ
 */
class Invitation {
    invitation_id;
    invitation_date;
    invitation_status;
    partner;
    employee;
    input_date;
    memo;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.invitation_id = parseInt(properties.invitation_id, 10);
        this.invitation_date = properties.invitation_date;
        this.invitation_status = parseInt(properties.invitation_status, 10);
        this.partner = new Partner(properties.partner);
        this.employee = new Employee(properties.employee);
        this.input_date = properties.input_date;
        this.memo = properties.memo;
    }

    get input_date_display() {
        return dateYmd(this.input_date);
    }

    get invitation_date_display() {
        return dateYmd(this.invitation_date);
    }
}

export default Invitation;
