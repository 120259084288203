import { isNud } from '@/utilities/typing'
import Schedule from '@/models/entities/schedule';
import Equipment from '@/models/entities/equipment';
import IgnoreAlert from '@/models/enums/schedule/ignore-alert';

/**
 * スケジュール備品トラン エンティティ
 */
class ScheduleEquipment {
    schedule_equipment_id;
    schedule;
    equipment;
    quantity;
    ignore_alert;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.school_id = properties.school_id;
        this.schedule = new Schedule(properties.schedule);
        this.equipment = new Equipment(properties.equipment);
        this.quantity = properties.quantity;
        this.ignore_alert = properties.ignore_alert ?? IgnoreAlert.ALERT;
    }
}

export default ScheduleEquipment;
