import { isNud } from '@/utilities/typing';
import { dateYmd, dateYmdForInputDate } from '@/utilities/date-format';
import School from '@/models/entities/school';
import Event from '@/models/entities/event';
import Absence from '@/models/entities/absence';
import SpareSchedule from '@/models/entities/spare-schedule';
import ScheduleEquipment from '@/models/entities/schedule-equipment';
import ScheduleFile from '@/models/entities/schedule-file';
import ScheduleHistory from '@/models/entities/schedule-history';
import ScheduleMemo from '@/models/entities/schedule-memo';
import SchedulePhotographer from '@/models/entities/schedule-photographer';
import ScheduleType from '@/models/enums/schedule/schedule-type';
import HasSeveralDays from '@/models/enums/schedule/has-several-days';
import IsUnfixed from '@/models/enums/schedule/is-unfixed';
import ScheduleStatus from '@/models/enums/schedule/schedule-status';
import Studio from '@/models/entities/studio';

/**
 * スケジュールトラン エンティティ
 */
class Schedule {
    schedule_id;
    school;
    event;
    schedule_status;
    schedule_name;
    schedule_type;
    studio;
    schedule_date;
    hour_from;
    minute_from;
    hour_to;
    minute_to;
    is_unfixed;
    unfixed_type;
    has_several_days;
    first_schedule;
    end_date;
    end_hour;
    end_minute;
    rain_type;
    spare_schedule;
    checked_date;
    photographer_number;
    transportation;
    schedule_place;
    contact_person;
    contact_tel;
    note;
    has_first_confirm_alert;
    has_last_confirm_alert;
    has_spare_schedule_alert;
    has_unselected_photographer_schedule_alert;
    has_no_contact_photographer_schedule_alert;
    has_status_list_alert;
    absence;

    files;
    cars;
    equipments;
    photographers;
    memos;
    histories;


    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.schedule_id = properties.schedule_id;
        this.school = new School(properties.school);
        this.event = new Event(properties.event);
        this.schedule_name = properties.schedule_name;
        this.schedule_status = properties.schedule_status;
        this.schedule_type = properties.schedule_type;
        this.studio = new Studio(properties.studio);
        this.schedule_date = properties.schedule_date;
        this.hour_from = properties.hour_from;
        this.minute_from = properties.minute_from;
        this.hour_to = properties.hour_to;
        this.minute_to = properties.minute_to;
        this.is_unfixed = properties.is_unfixed ?? IsUnfixed.FIXED;
        this.unfixed_type = properties.unfixed_type;
        this.has_several_days = properties.has_several_days ?? HasSeveralDays.NO;
        this.first_schedule = properties.first_schedule;
        this.end_date = properties.end_date;
        this.end_hour = properties.end_hour;
        this.end_minute = properties.end_minute;
        this.rain_type = properties.rain_type;
        this.spare_schedule = new SpareSchedule(properties.spare_schedule);
        this.checked_date = properties.checked_date;
        this.photographer_number = properties.photographer_number ?? 1;
        this.transportation = properties.transportation;
        this.schedule_place = properties.schedule_place;
        this.contact_person = properties.contact_person;
        this.contact_tel = properties.contact_tel;
        this.note = properties.note;
        this.has_first_confirm_alert = properties.has_first_confirm_alert;
        this.has_last_confirm_alert = properties.has_last_confirm_alert;
        this.has_spare_schedule_alert = properties.has_spare_schedule_alert;
        this.has_unselected_photographer_schedule_alert	 = properties.has_unselected_photographer_schedule_alert;
        this.has_no_contact_photographer_schedule_alert = properties.has_no_contact_photographer_schedule_alert;
        this.has_status_list_alert = properties.has_status_list_alert;
        this.absence = new Absence(properties.absence);

        this.cars = [];
        if ('cars' in properties) {
            for (let car_id of properties.cars) {
                this.cars.push(parseInt(car_id, 10));
            }
        }

        this.files = [];
        if ('files' in properties) {
            for (let file of properties.files) {
            this.files.push(new ScheduleFile(file));
            }
        }

        this.equipments = [];
        if ('equipments' in properties) {
            for (let equipment of properties.equipments) {
            this.equipments.push(new ScheduleEquipment(equipment));
            }
        }
        this.photographers = [];
        if ('photographers' in properties) {
            for (let photographer of properties.photographers) {
                this.photographers.push(new SchedulePhotographer(photographer));
            }
        }
        this.memos = [];
        if ('memos' in properties) {
            for (let memo of properties.memos) {
            this.memos.push(new ScheduleMemo(memo));
            }
        }
        this.histories = [];
        if ('histories' in properties) {
            for (let history of properties.histories) {
            this.histories.push(new ScheduleHistory(history));
            }
        }
    }

    /**
     * getter
     */
    // schedule_dateをYYYY.MM.DDで表示
    get schedule_date_display() {
        return dateYmd(this.schedule_date);
    }
    // schedule_dateをYYYY-MM-DDで表示(入力系で使う)
    get schedule_date_for_input() {
        return dateYmdForInputDate(this.schedule_date);
    }
    // checked_dateをYYYY-MM-DDで表示(入力系で使う)
    get checked_date_for_input() {
        return dateYmdForInputDate(this.checked_date);
    }
    // end_dateをYYYY-MM-DDで表示(入力系で使う)
    get end_date_for_input() {
        return dateYmdForInputDate(this.end_date);
    }
    // スケジュールの名前を表示
    get schedule_name_display() {
        let school_shortened_name = !isNud(this.school.school_id) ? this.school.shortened_name : '';
        let event_name = !isNud(this.event.event_id) ? this.event.event_name : '';

        return `${school_shortened_name} ${event_name} ${this.schedule_name ?? ''}`;
    }
    // スケジュールの短い名前を表示（社用車表示用）
    get schedule_name_shortened() {
        // 学校があれば学校名
        if (!isNud(this.school.school_shortened_name)) {
            return this.school.school_shortened_name;
        }
        if (!isNud(this.school.school_name)) {
            return this.school.school_name;
        }
        // なければ予定名先頭
        if (this.schedule_name.length > 10) {
            return this.schedule_name.substring(0, 10) + '...'
        }
        return this.schedule_name;
    }
    // スケジュールの時間を表示
    get schedule_time() {
        let minute_from = this.minute_from === 0 ? '00' : this.minute_from;
        let minute_to = this.minute_to === 0 ? '00' : this.minute_to;

        return `${this.hour_from}:${minute_from} 〜 ${this.hour_to}:${minute_to}`;
    }
    // スケジュールの種類に対応するアイコンを表示
    get schedule_icon() {
        if (this.has_several_days === HasSeveralDays.YES) {
            if (this.first_schedule && 'schedule_id' in this.first_schedule) {
                return 'bi-align-center';

            } else {
                return 'bi-align-start';
            }
        } else if (this.schedule_type === ScheduleType.SHOOTING) {
            return 'bi-camera2';

        } else if (this.schedule_type === ScheduleType.EVENT_SHOOTING) {
            return 'bi-balloon-fill';

        } else if (this.schedule_type === ScheduleType.MEETING) {
            return 'bi-chat-dots';

        } else if (this.schedule_type === ScheduleType.SPARE) {
            return 'bi-umbrella-fill';
        } else if (this.schedule_type === ScheduleType.ABSENCE) {
            return 'bi-moon-stars';
        } else if (this.schedule_type === ScheduleType.OTHERS) {
            return 'bi-box-seam';
        }

        return ''
    }
    get schedule_status_icon() {
        if (this.schedule_status === ScheduleStatus.FINISHED) {
            return 'bi-check-circle-fill';
        } else if (this.schedule_status === ScheduleStatus.POSTPONED) {
            return 'bi-skip-forward-fill';
        } else if (this.schedule_status === ScheduleStatus.CANCELED) {
            return 'bi-dash-circle-fill';
        }
        return '';
    }
    // スケジュールステータスの日本語を表示
    get schedule_status_display() {
        return ScheduleStatus.get(this.schedule_status);
    }

    /**
     * setter
     */
    set schedule_date_for_input(value) {
        return this.schedule_date = value;
    }

    set checked_date_for_input(value) {
        return this.checked_date = value;
    }
    set end_date_for_input(value) {
        return this.end_date = value;
    }


}

export default Schedule;
