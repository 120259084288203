import Enum from '../enum'

/**
 * スケジュールカメラマン 連絡済かどうか
 */
class IsContacted extends Enum {
    static NO = 0; // 未連絡
    static YES = 1; // 連絡済

    static values() {
        return {
            [this.NO]: '未連絡',
            [this.YES]: '連絡済',
        }
    }
}

export default IsContacted;