import Enum from '../enum'

/**
 * スケジュール ステータス
 */
class ScheduleStatus extends Enum {
    static PENDING = 1; // 未完了
    static FINISHED = 2; // 完了
    static POSTPONED = 3; // 延期
    static CANCELED = 9; // キャンセル

    static values() {
        return {
            [this.PENDING]: '未完了',
            [this.FINISHED]: '完了',
            [this.POSTPONED]: '延期',
            [this.CANCELED]: 'キャンセル',
        }
    }
}

export default ScheduleStatus;