import Enum from '../enum'

/**
 * 休暇トラン 休みの時間帯
 *
 * [1:全休 2:午前休 3:午後休]
 */
class AbsenceTime extends Enum {
    static ALL_DAY = 1; // 全休
    static AM = 2; // 午前休
    static PM = 3; // 午後休

    static values() {
        return {
            [this.ALL_DAY]: '全休',
            [this.AM]: '午前休',
            [this.PM]: '午後休',
        }
    }
}

export default AbsenceTime;